.notificationContainer {
  left: 50%;
  position: fixed;
  top: 40px;
  transform: translateX(-50%);
  z-index: var(--z-index-notification);
}

.notification {
  background-color: var(--color-green);
  border-radius: var(--border-radius);
  color: var(--color-white);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  overflow: hidden;
  padding: 12px 24px;
  position: relative;
  transition: transform 200ms ease-out, opacity 200ms ease-out;
}
