.maxWidth {
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}

.s {
  max-width: 720px;
}

.m {
  max-width: 960px;
}

.l {
  max-width: 1280px;
}

.full {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
