.input {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  color: var(--color-gray-text);
  display: block;
  font-family: inherit;
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  padding: 20px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.inlineInput {
  background-color: rgba(0, 0, 0, 0.05);
  border: 0;
  margin: 0;
  padding: 0;
}
