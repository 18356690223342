.heading1 {
  border: none;
  box-shadow: none;
  color: var(--color-gray-text);
  display: block;
  font-family: inherit;
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-s);
  margin: 48px 0 calc(0.5 * var(--font-size-xxxl));
  padding: 0;
  width: 100%;

  @media (--viewport-m) {
    font-size: var(--font-size-xxxxl);
    margin: 72px 0 calc(0.5 * var(--font-size-xxxxl));
  }
}
