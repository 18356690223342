.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (--viewport-l) {
    margin-left: var(--sidebar-width);
  }
}

.content {
  flex: 1;
  padding: 0 0 48px;
  position: relative;
}

.footer {
  color: var(--color-gray-text-light);
  font-size: var(--font-size-s);
  margin: 0 auto;
  padding: 20px 32px;
  text-align: center;
  width: 100%;

  @media (--viewport-m) {
    padding: 40px 32px;
  }

  @media (--viewport-l) {
    padding: 60px 32px;
  }
}

.footerLogo {
  height: 40px;
  margin: 0 auto 32px;
  width: 60px;
}

.footerButton {
  background-color: transparent;
  border: 0;
  color: var(--color-gray-text-light);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  margin: 10px auto 0;
  padding: 0;
  text-decoration: underline;
  transition: color 200ms ease-out;

  &:hover,
  &:focus {
    color: var(--color-editor-blue);
  }
}
