.container {
  position: relative;
}

.inputContainer {
  position: relative;

  & svg {
    fill: var(--color-gray-text-light);
    height: 15px;
    left: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }
}

.label {
  left: -999999px;
  position: absolute;
  visibility: hidden;
}

.input {
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  color: var(--color-gray-text);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xs);
  outline: none;
  padding: 12px 17px 12px 34px;
  width: 100%;
}

.resultsContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translateY(calc(100% - 1px));
}

.result {
  background-color: var(--color-white);
  border: solid 1px var(--color-gray-silver);
  color: var(--color-gray-text);
  display: block;
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  padding: 20px 40px 20px 20px;
  position: relative;
  text-decoration: none;
  transition: background-color 200ms ease-out;
  width: 200%;

  & svg {
    fill: var(--color-gray-silver-dark);
    height: 15px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-background);
  }

  &:not(:first-child) {
    border-top: none;
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.resultHeader {
  align-items: center;
  display: flex;
}

.title {
  font-weight: var(--font-weight-semibold);
  margin: 0 10px 0 0;
}

.group {
  color: var(--color-gray-text-light);
  font-size: var(--font-size-s);
}

.preview {
  display: block;
  line-height: var(--line-height-m);
  margin: 6px 0 0;

  & em {
    font-style: normal;
    background-color: var(--color-gray-silver);
  }
}
