.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-loader);
}

.loader {
  height: 40px;
  margin: 40px auto;
  position: relative;
  width: 40px;
}

.one,
.two {
  animation: loadingAnimation 2s infinite ease-in-out;
  background-color: var(--color-gray-silver-light);
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
}

.two {
  animation-delay: -1s;
}

@keyframes loadingAnimation {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
