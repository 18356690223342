.inputContainer {
  display: block;
}

.inputLabel {
  color: var(--color-gray-text);
  display: block;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-xs);
  margin: 0 0 10px;
}
