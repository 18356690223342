.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-sidebar-overlay);
}

.mobileContainer {
  align-items: center;
  border-bottom: solid 2px var(--color-gray-silver);
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;

  @media (--viewport-l) {
    display: none;
  }
}

.mobileMenuButton {
  cursor: pointer;
  height: 16px;
  padding: 0;
  width: 16px;

  & svg {
    fill: var(--color-editor-shade-dark);
    height: 16px;
    width: 16px;
  }

  @media (--viewport-l) {
    display: none;
  }
}

.mobileMenuButtonInner {
  align-items: center;
  color: var(--color-editor-shade-dark);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);

  & > svg {
    flex-shrink: 0;
  }

  & span {
    padding-left: 12px;
  }
}

.container {
  background-color: var(--color-gray-silver-background);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  max-width: var(--sidebar-width);
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform 200ms ease-out;
  width: 100%;
  will-change: transform;
  z-index: var(--z-index-sidebar);

  @media (--viewport-l) {
    transform: translateX(0);
  }
}

.isOpen {
  transform: translateX(0);
}

.logoSearchContainer {
  padding: 24px 16px;

  @media (--viewport-l) {
    padding: 24px;
  }
}

.logoContainer {
  margin: 0 0 16px;

  @media (--viewport-l) {
    margin: 0 0 24px;
  }
}

.sidebarLogo {
  display: none;

  @media (--viewport-l) {
    display: block;
  }
}

.searchBoxContainer {
  position: relative;
  z-index: 2;
}

.menuContainer {
  flex: 1;
  -ms-overflow-style: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu {
  padding: 0 16px;

  @media (--viewport-l) {
    padding: 0 24px;
  }
}

.bottomMenuContainer {
  border-top: solid 1px var(--color-gray-silver);
  margin: 0 16px;
  padding: 16px 0;

  @media (--viewport-l) {
    padding: 24px 0;
    margin: 0 24px;
  }
}

.logo {
  display: block;
  max-height: 30px;
  max-width: 100%;

  @media (--viewport-l) {
    max-height: 40px;
  }
}

.footerMenuButton {
  border-radius: var(--border-radius);
  color: var(--color-gray-text);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  margin: 0;
  padding: 12px 0 12px 16px;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }
}

.footerMenuButtonInner {
  align-items: center;
  display: flex;

  & svg {
    fill: var(--color-editor-shade-dark);
    height: 16px;
    margin-right: 8px;
    width: 16px;
  }
}

.menuChapterTitle {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--color-gray-text);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  margin: 0;
  padding: 12px 8px;

  & svg {
    fill: var(--color-gray-silver);
    height: 8px;
    margin: 0 8px 0 0;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: transform 200ms ease-out;
    width: 8px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }
}

.menuChapterTitle.menuChapterTitleOpen svg {
  transform: rotate(90deg);
}

.withMenuButtons {
  position: relative;

  &:hover .withMenuButtonsDragIcon,
  &:focus .withMenuButtonsDragIcon,
  &:hover .withMenuButtonsMoreIcon,
  &:focus .withMenuButtonsMoreIcon {
    display: block;
  }
}

.withMenuButtonsDragIcon,
.withMenuButtonsMoreIcon {
  cursor: pointer;
  height: 16px;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  display: none;

  & svg {
    fill: currentColor;
    height: 16px;
    width: 16px;
  }
}

.withMenuButtonsDragIcon {
  color: var(--color-gray-silver-dark);
  left: 8px;
}

.withMenuButtonsMoreIcon {
  color: var(--color-editor-blue);
  right: 8px;
}

.addButtonInner {
  align-items: center;
  display: flex;

  & svg {
    height: 12px;
    margin-right: 8px;
    width: 12px;
  }
}

.menuChapterAddGroupButton {
  border-radius: var(--border-radius);
  color: var(--color-editor-blue);
  cursor: pointer;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  padding: 12px 0 12px 24px;
  text-align: left;
  width: 100%;

  @media (--viewport-l) {
    padding: 12px 0 12px 32px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }
}

.menuGroupTitle {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--color-gray-text);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0;
  text-decoration: none;
  padding: 12px 24px;
  text-decoration: none;

  @media (--viewport-l) {
    padding: 12px 32px;
  }

  & svg {
    fill: var(--color-gray-silver);
    height: 8px;
    margin: 0 8px 0 0;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: transform 200ms ease-out;
    width: 8px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }
}

.menuGroupTitle.menuGroupTitleOpen svg {
  transform: rotate(90deg);
}

.addPageButton {
  border-radius: var(--border-radius);
  color: var(--color-editor-blue);
  cursor: pointer;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  padding: 12px 0 12px 40px;
  text-align: left;
  width: 100%;

  @media (--viewport-l) {
    padding: 12px 0 12px 56px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }

  &.isFakeGroup {
    padding: 12px 24px;

    @media (--viewport-l) {
      padding: 12px 32px;
    }
  }
}

.menuPage {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--color-gray-text);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-s);
  margin: 0;
  padding: 12px 40px;
  text-decoration: none;

  @media (--viewport-l) {
    padding: 12px 56px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-light);
  }

  &.menuPageActive {
    background-color: var(--color-gray-silver-light);
  }

  &.inFakeGroup {
    padding: 12px 24px;

    @media (--viewport-l) {
      padding: 12px 32px;
    }
  }
}

.menuPage svg {
  fill: var(--color-gray-text);
  height: var(--font-size-s);
  margin-left: 8px;
  width: var(--font-size-s);
}
