.richText {
  color: var(--color-gray-text);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);

  & h2,
  & h3,
  & h4 {
    color: var(--color-gray-text);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-s);

    &:first-child {
      margin-top: 0;

      @media (--viewport-m) {
        margin-top: 0;
      }
    }

    & + p,
    & + ul,
    & + ol {
      margin-top: 0;
    }
  }

  & h2 {
    font-size: var(--font-size-xxl);
    margin: calc(2 * var(--font-size-xxl)) 0 calc(0.5 * var(--font-size-xxl));

    @media (--viewport-m) {
      font-size: var(--font-size-xxxl);
      margin: calc(2 * var(--font-size-xxxl)) 0 calc(0.5 * var(--font-size-xxxl));
    }
  }

  & h3 {
    font-size: var(--font-size-xl);
    margin: calc(2 * var(--font-size-xl)) 0 calc(0.5 * var(--font-size-xl));

    @media (--viewport-m) {
      font-size: var(--font-size-xxl);
      margin: calc(2 * var(--font-size-xxl)) 0 calc(0.5 * var(--font-size-xxl));
    }
  }

  & h4 {
    font-size: var(--font-size-l);
    margin: calc(2 * var(--font-size-l)) 0 calc(0.5 * var(--font-size-l));

    @media (--viewport-m) {
      font-size: var(--font-size-xl);
      margin: calc(2 * var(--font-size-xl)) 0 calc(0.5 * var(--font-size-xl));
    }
  }

  & p,
  & ul,
  & ol {
    margin: calc(1.5 * var(--font-size-m)) 0;

    &:first-child {
      margin-top: 0;

      @media (--viewport-m) {
        margin-top: 0;
      }
    }
  }

  & ul,
  & ol {
    line-height: var(--line-height-l);
    margin: 0 0 2px;

    & > li:not(:last-child) {
      margin: 0 0 4px;
    }
  }

  & a {
    color: var(--brand-color);
    font-weight: var(--font-weight-bold);
    transition: opacity 200ms ease-out;
    text-decoration: underline;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}
