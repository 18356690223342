.overlay {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-overlay);
}

.background {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.container {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  max-width: 720px;
  overflow-y: scroll;
  padding: 60px 40px 40px;
  scrollbar-width: none;
  top: 100px;
  position: relative;
  width: 100%;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  & h2 {
    margin: 0 0 0.5em;
  }

  & p {
    margin: 0.5em 0 2em;
  }
}

.closeButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;

  & svg {
    fill: var(--color-gray-text);
    height: 20px;
  }
}
