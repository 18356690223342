.button {
  background-color: transparent;
  border-radius: var(--border-radius);
  border: solid 2px var(--color-editor-blue);
  color: var(--color-editor-blue);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  padding: 9px 12px;
  text-align: left;
  text-decoration: none;
  transition: background-color 200ms ease-out, color 200ms ease-out, opacity 200ms ease-out;

  &:hover,
  &:focus {
    background-color: var(--color-editor-blue-hover);
    border-color: var(--color-editor-blue-hover);
    color: var(--color-white);

    & svg {
      fill: var(--color-white);
    }
  }
}

.button:disabled {
  opacity: 0.2;
}

.inner {
  align-items: center;
  display: flex;
}

.icon {
  height: 16px;
  margin-right: 8px;
  width: 16px;

  & svg {
    fill: var(--color-editor-blue);
    height: 16px;
    transition: fill 200ms ease-out;
    width: 16px;
  }
}

.filled {
  background-color: var(--color-editor-blue);
  border-color: var(--color-editor-blue);
  color: var(--color-white);

  & svg {
    fill: var(--color-white);
  }
}

.gray {
  background-color: transparent;
  border-color: var(--color-gray-text-light);
  color: var(--color-gray-text-light);

  & svg {
    fill: var(--color-gray-text-light);
  }

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver-background);
    border-color: var(--color-gray-text);
    color: var(--color-gray-text);

    & svg {
      fill: var(--color-gray-text);
    }
  }
}
